.block {
  width: 100%;

  .label {
    margin-bottom: 4px;
  }

  .textarea {
    width: calc(100% - 28px);
    height: 92px;
    font-size: 16px;
    line-height: 23px;
    border-radius: 4px;
    border: 1px solid gray;
    padding: 8px 14px;
    cursor: text;
    resize: none;
    outline: none;
  }
}
