.header {
  text-align: center;
}

.input {
  margin-bottom: 20px;
}

.button {
  width: 100%;
}
