.block {
  display: flex;
  flex-direction: column;

  .content {
    cursor: pointer;

    .img {
      width: 150px;
    }
  }

  .button {
    margin-top: 5px;
    border: none;
    background: none;
    padding: 0;
  }
}
