.block {
  width: calc(100%);
  height: 100%;
  display: flex;

  @media (max-width: 940px) {
    height: calc(100% - 58px);
  }

  .mainStreamContainer {
    width: calc(100% - 350px - 10px - 20px - 10px);
    height: calc(100% - 20px - 52px - 10px);
    box-shadow:
      2px 2px 6px #0000000f,
      -2px -2px 6px #0000000f;
    margin-right: 10px;
    border-radius: 6px;
    padding: 10px;

    &__otherBlock {
      @media (max-width: 940px) {
        display: none;
      }
    }

    &__notOtherBlock {
      width: calc(100% - 20px);
      margin-right: 0;
    }

    &__fullscreen {
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      background: white;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &__translation {
      box-shadow:
        2px 2px 6px #5eead4,
        -2px -2px 6px #5eead4;
    }
  }

  .otherBlock {
    width: 340px;
    height: calc(100% - 20px - 52px - 10px);
    box-shadow:
      2px 2px 6px #0000000f,
      -2px -2px 6px #0000000f;
    border-radius: 6px;
    padding: 10px;

    @media (max-width: 940px) {
      width: 100%;
    }

    &__hidden {
      display: none;
    }
  }
}
