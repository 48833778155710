.label {
  margin-bottom: 4px;
}

.block {
  width: calc(100% - 28px);
  height: 23px;
  border-radius: 4px;
  border: 1px solid gray;
  padding: 8px 14px;
  cursor: text;

  .input {
    font-size: 16px;
    line-height: 23px;
    height: 23px;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    width: 100%;
  }
}
