.content {
  width: 300px;

  .description {
    text-align: center;
    font-size: 18px;
    margin-bottom: 14px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    .button {
      width: calc(50% - 5px);

      &__red {
        color: red;
        border-color: red;
      }
    }
  }
}
