.content {
  width: 300px;
  display: flex;
  flex-direction: column;

  .button {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &__red {
      border-color: red;
      color: red;
    }
  }
}
