.notification {
  padding: 14px;
  border-radius: 6px;
  background: #e5e7eb;
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 20px;

  .block {
    position: relative;

    .button {
      background: white;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      border: 1px solid black;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -26px;
      top: -26px;
      padding: 0;
    }
  }
}
