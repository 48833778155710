.block {
  width: 100%;
  position: relative;

  .label {
    margin-bottom: 4px;
  }

  .field {
    width: calc(100% - 28px);
    height: 23px;
    border-radius: 4px;
    border: 1px solid gray;
    padding: 8px 14px;
    font-size: 16px;
    line-height: 23px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__visiblePlaceholder {
      user-select: none;
      color: rgb(133, 133, 133);
    }

    &__isOpen {
      border-radius: 4px 4px 0 0;
    }

    &__isOpenTop {
      border-radius: 0 0 4px 4px;
    }

    .icon {
      &__open {
        transform: rotate(180deg);
      }
    }
  }

  .list {
    width: 100%;
    position: absolute;
    background: white;
    border-radius: 0 0 4px 4px;
    border: 1px solid gray;
    border-top: none;
    z-index: 1;

    &__top {
      border-top: 1px solid gray;
      bottom: 40px;
      border-radius: 4px 4px 0 0;
    }

    .item {
      padding: 8px 14px;
      text-align: center;
      border-bottom: 1px solid gray;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
