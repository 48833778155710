.modal {
  max-width: calc(100% - 40px - 20px);
  max-height: calc(100% - 40px - 20px);

  .content {
    max-width: 100%;
    max-height: calc(100% - 24px - 26px - 28px - 10px);

    .photo {
      max-width: 100%;
      max-height: calc(100% - 60px);

      .image {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .button {
      width: calc(50% - 5px);

      &__red {
        color: red;
        border-color: red;
      }
    }
  }
}
