.container {
  width: 500px;
  height: 100%;
  /* display: flex;
  justify-content: center;
  flex-direction: column; */

  .title {
    height: 24px;
  }

  .videoContainter {
    height: calc(100% - 24px);
    position: relative;

    .video {
      width: 100%;
      max-height: 100%;
    }

    .video__notStream {
      height: 100%;
      background: black;
    }
  }
}

.bitrate {
  position: absolute;
  bottom: 4px;
  right: 0;
  padding: 2px;
  font-size: 10px;
  background: #1d4ed8;
  color: white;
}

.videoResolution {
  position: absolute;
  bottom: 4px;
  left: 0;
  padding: 2px;
  font-size: 10px;
  background: #1d4ed8;
  color: white;
}

.elements {
  position: absolute;
  top: 0;
  cursor: pointer;

  .elementsContainer {
    width: 100%;
    height: 100%;
    position: relative;

    .horizontalLine {
      width: 100%;
      height: 2px;
      opacity: 0.7;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background: red;
    }

    .verticalLine {
      width: 2px;
      height: 100%;
      opacity: 0.7;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: red;
    }

    .circle {
      width: 100px;
      height: 100px;
      opacity: 0.7;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 50%;
      border: 2px solid red;
    }

    .activeBlock {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.positionControlContainer {
  position: absolute;
  left: 20px;
  bottom: 20px;
  user-select: none;

  &__fullscreen {
    bottom: 80px;
  }

  .positionControl {
    width: 100px;
    height: 100px;
    position: relative;
    transform: rotate(-45deg);

    .horizontalLine {
      width: 100%;
      height: 4px;
      position: absolute;
      top: 48px;
      background: white;
      opacity: 0.5;
    }

    .verticalLine {
      width: 4px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 48px;
      background: white;
      opacity: 0.5;
    }

    .item {
      width: 50px;
      height: 50px;
      position: absolute;
      opacity: 0.5;

      &__active {
        opacity: 0.7;
      }

      .itemContent {
        position: relative;

        .quarterCircle {
          width: 50px;
          height: 50px;
          border-radius: 0 100% 0 0;
          background: black;
        }

        .arrow {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          transform: rotate(45deg);
        }
      }
    }
  }
}
