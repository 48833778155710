.button {
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid;
  background: white;
  border-color: blue;
  color: blue;

  &:disabled {
    cursor: default;
    color: #00000061;
    border-color: #00000061;
  }
}
