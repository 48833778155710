.container {
  width: 500px;
  height: calc(100vh - 40px - 150px);
  margin: 0 auto;
  padding: 40px 0 150px 0;
  position: relative;

  .title {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 128px;
  }

  .button {
    width: 100%;
    height: 40px;
    margin-top: 40px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      font-size: 28px;
      color: green;
      font-weight: 600;
      margin-left: 10px;
    }
  }
}
