.container {
  position: relative;
  width: 100%;
  height: 100%;

  .rating {
    font-size: 32px;
    text-align: center;
    margin-bottom: 12px;
  }

  .discription {
    font-size: 24px;
    text-align: center;
    margin-top: 46px;
  }

  .button {
    height: 40px;
    width: 320px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 20px;
  }
}
