.container {
  height: 100%;

  .button {
    height: 38px;
    width: 100px;

    &__red {
      border-color: red;
      color: red;
    }
  }
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: red;
  background-image: linear-gradient(126deg, transparent 50%, white 50%),
    linear-gradient(90deg, white 50%, transparent 50%);
}
