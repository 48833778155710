.content {
  width: 300px;

  .item {
    margin-bottom: 14px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .button {
    width: calc(50% - 5px);

    &__red {
      color: red;
      border-color: red;
    }

    &__delete {
      color: red;
      border: none;
      padding: 0;
      text-align: left;
      margin-top: 26px;
    }
  }
}
