.block {
  max-width: 1350px;
  height: 180px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 940px) {
    display: block;
  }

  .item {
    width: 300px;
    text-align: center;

    @media (max-width: 940px) {
      width: 100%;
    }

    &__myHeadset {
      margin: 0 auto;
    }

    .title {
      font-weight: 600;
      margin-bottom: 10px;

      @media (max-width: 940px) {
        display: none;
      }
    }

    .button {
      width: calc(100% - 20px);
      margin-bottom: 10px;

      @media (max-width: 940px) {
        width: 100%;
      }
    }

    .description {
      @media (max-width: 940px) {
        display: none;
      }
    }
  }
}
