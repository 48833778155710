.header {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 20px;
  font-weight: 600;
  position: relative;

  .button {
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }

  .title {
    font-size: 20px;

    @media (max-width: 940px) {
      font-size: 18px;
    }
  }
}
