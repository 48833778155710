.header {
  text-align: center;
}

.input {
  margin-bottom: 20px;
}

.button {
  width: 100%;
}

.buttonPasswordReset {
  margin-top: 20px;
  padding: 0;
  border: none;
}
