.block {
  width: 100%;
  height: calc(100% - 180px - 20px - 64px);
  margin-bottom: 20px;

  @media (max-width: 940px) {
    height: auto;
  }

  .video {
    width: auto;
  }
}
