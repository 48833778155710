.button {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  outline: none;
  position: fixed;
  left: 1px;
  bottom: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d1d5db;
  background: white;
}
