.block {
  display: flex;
  justify-content: center;
  margin: 10px auto;

  @media (max-width: 940px) {
    display: block;
  }
}

.dropdown {
  width: 400px;
  margin: 0 5px;

  @media (max-width: 940px) {
    width: 100%;
    margin: 0 0 10px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
