.block {
  .labelContainer {
    display: flex;

    .label {
    }
    .labelValue {
    }
  }

  .input {
  }
}
