.column {
  width: calc(50% - 2px - 20px);
  height: calc(100% - 2px - 24px);
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  padding: 12px 10px;
  margin-right: 20px;

  @media (max-width: 940px) {
  }

  &:last-child {
    margin-right: 0;
  }

  .header {
    width: 100%;
    height: 62px;

    .button {
      border: none;
      padding: 0;
      margin-bottom: 10px;
    }

    .headerTitle {
      font-size: 20px;
    }
  }

  .content {
    height: calc(100% - 63px);
    overflow: auto;
  }
}
