.content {
  width: 300px;

  .item {
    margin-bottom: 10px;
  }

  .button {
    width: 100%;
    color: red;
    border-color: red;
  }
}
