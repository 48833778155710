.button {
  width: 36px;
  height: 36px;
  position: relative;
  background: none;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    border-color: rgba(16, 16, 16, 0.3);
  }

  &__off {
    opacity: 0.5;
    background: #ced3d4;
    border-color: #898989;
  }

  &__phoneDown {
    width: 72px;
    border-radius: 16px;
    background: #dc2626;
    border-color: #dc2626;
    color: white;
    font-size: 16px;
  }

  &__record {
    border-color: #dc2626;
  }

  &__snapshot {
    border-color: green;
    color: green;
    text-transform: uppercase;
    width: 120px;
    border-radius: 16px;
    font-weight: 600;
  }

  &__arrow {
    padding: 0;
    width: 20px;
    height: 20px;
    position: absolute;
  }

  &__translation {
    background: #5eead4;
  }

  .warning {
    position: absolute;
    top: -5px;
    right: -5px;
    background: white;
    border-radius: 50%;
  }
}

.videoResolution {
  width: 80px;
}

.selectBitrate {
  width: 100px;
}

.optionsContainer {
  position: relative;
  font-size: 12px;

  .optionsModal {
    padding: 10px;
    background: white;
    position: absolute;
    bottom: 50px;
    right: 0;
    border-radius: 6px;
    box-shadow:
      2px 2px 6px #0000000f,
      -2px -2px 6px #0000000f;
    width: 200px;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 10px;
    }
  }
}

.arrowsBlock {
  width: calc(20px * 3);
  height: 40px;
  position: relative;
}

.videoResolutionTabs {
  width: 170px;
}
