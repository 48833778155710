.label {
  font-size: 14px;
  margin-bottom: 4px;
}

.block {
  width: calc(100% - 8px);
  height: 30px;
  border-radius: 10px;
  background: rgb(246, 247, 248);
  display: flex;
  padding: 4px;
  justify-content: space-between;

  .tab {
    height: 100%;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
    user-select: none;

    &__active {
      background: blue;
      color: white;
    }
  }
}
