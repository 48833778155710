.page {
  min-height: 100vh;

  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    height: 70px;
    border-bottom: 1px solid black;

    .logo {
      position: absolute;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 40px;
      color: black;
      text-decoration: none;
      font-size: 34px;
    }

    .userName {
      position: relative;
      cursor: pointer;
      height: 100%;
      padding: 0 20px;
      display: flex;
      align-items: center;

      @media (hover: hover) {
        &:hover {
          background: #f3f4f6;
        }
      }

      .role {
        @media (max-width: 940px) {
          display: none;
        }
      }

      .arrow {
        margin-left: 10px;

        &__close {
          transform: rotate(180deg);
        }
      }

      .informationBlock {
        position: absolute;
        padding: 14px;
        border-radius: 6px;
        border: 1px solid #e5e5e5;
        background: white;
        top: 68px;
        right: 0;
        width: 220px;
        z-index: 1;
        cursor: default;

        .balance {
          width: calc(100% - 20px);
          padding: 5px 10px;
          background: #facc15;
          border-radius: 6px;
          margin: 10px 0;
        }

        .logoutButton {
          width: 100%;
          border-color: red;
          color: red;
        }

        .languageSelector {
          position: static;
          margin-top: 10px;
        }
      }
    }

    .confirmButton {
      margin-right: 10px;
    }
  }

  .content {
    padding: 10px 20px 20px 20px;
    height: calc(100vh - 30px);

    &__hasHeade {
      height: calc(100vh - 30px - 70px);
    }
  }
}
