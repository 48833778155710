.block {
  width: 300px;
  padding: 0 16px;

  .conteiner {
    width: 100%;
    height: 226px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 14px;

    .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .description {
      margin-bottom: 8px;
    }

    .radioButtonsContainer {
      .item {
        margin-bottom: 4px;
      }
    }
  }
}
