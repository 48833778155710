.background {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 2;
}

.modal {
  width: fit-content;
  height: fit-content;
  z-index: 3;
  padding: 20px;
  position: absolute;
  background: white;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 12px;

  &__hidden {
    display: none;
  }

  .title {
    font-size: 20px;
    margin-bottom: 26px;
    text-align: center;
  }
}
