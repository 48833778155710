.container {
  position: relative;
  width: 100%;
  height: calc(100% - 100px);
  padding-top: 100px;

  .blocks {
    max-width: 1520px;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    overflow: auto;

    .scrollBlocks {
      min-width: 1000px;
      display: flex;
      justify-content: space-between;
    }
  }

  .button {
    height: 40px;
    width: 320px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 20px;
  }
}
