.block {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .previewBlock {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .preview {
      border-radius: 50%;
    }
  }

  .color {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid;
    cursor: pointer;
  }
}
