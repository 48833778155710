.block {
  cursor: pointer;

  .input {
    cursor: pointer;
  }

  .label {
    font-size: 18px;
    margin-left: 4px;
    cursor: pointer;
  }
}
