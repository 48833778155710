.block {
  width: calc(100% - 20px - 40px);
  background: white;
  height: 52px;
  border-radius: 6px;
  box-shadow:
    2px 2px 6px #0000000f,
    -2px -2px 6px #0000000f;
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  bottom: 20px;

  &__fullscreen {
    z-index: 2;
    opacity: 0.7;
  }

  @media (max-width: 940px) {
    overflow-x: auto;
    overflow-y: visible;
    position: relative;
    bottom: 0;
    width: calc(100% - 20px);
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 940px) {
      min-width: 900px;
    }
  }
}
