.modal {
  width: calc(100% - 40px - 20px);
  height: calc(100% - 40px - 20px - 30px);

  .content {
    width: 100%;
    height: 100%;

    .img {
      width: 100%;
      height: calc(100% - 20px - 32px);
      object-fit: contain;
      object-position: center;
    }

    .button {
      width: 100%;
      color: red;
      border-color: red;
    }
  }
}
