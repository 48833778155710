.page {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content {
    width: 320px;
    margin: 0 auto;
    padding: 10px 0;

    @media (max-width: 940px) {
      padding-top: 30px;
    }
  }

  .video {
    width: 100%;
    max-height: calc(100% - 470px - 20px - 10px); //470 самая большая высота контента (высота регистрации)
    margin: 10px auto 0 auto;
    display: block;
  }
}
