.banner {
  width: 100%;
  height: calc(100% - 180px - 20px - 64px);
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    white-space: pre-line;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }
}
