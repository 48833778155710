.mainStreamBlock {
  width: 100%;
  height: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;

  &__fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: white;
  }

  .videoContainer {
    width: 100%;
    height: calc(100%);
  }
}

.allStreamsBlock {
  width: 100%;
  height: 100%;
  overflow: auto;

  &__hidden {
    display: none;
  }

  .item {
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .videoContainer {
      width: 100%;

      .video {
        width: calc(100% - 2px);
        border: 1px solid white;
        cursor: pointer;

        &__active {
          border-color: blue;
        }
      }
    }
  }
}
