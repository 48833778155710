.starts {
  display: flex;
  justify-content: center;
  margin-right: 10px;

  .starContainer {
    position: relative;

    .darkening {
      height: 100%;
      position: absolute;
      right: 0;
      background: white;
      opacity: 0.6;
    }
  }
}
