.block {
  width: 100px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .button {
    width: 36px;
    height: 36px;
    position: relative;
    background: none;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    cursor: pointer;

    &:disabled {
      border-color: rgba(16, 16, 16, 0.3);
    }

    &__off {
      opacity: 0.5;
      background: #ced3d4;
      border-color: #898989;
    }

    .warning {
      position: absolute;
      top: -5px;
      right: -5px;
      background: white;
      border-radius: 50%;
    }
  }
}
